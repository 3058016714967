<template>
<div>
  <!-- <div class="banner w-100">
    <img src="/images/about-banner.jpg" class="w-100">
  </div> -->
  <div class="main privacy" id="main"><!-- Main Section-->
    
    <div class="container">
      <div class="row justify-content-center">
          <div class="col-md-8 text-center">
              <h1 class="bold wow fadeInUp">About <span style="color: #f89827;">C</span><span style="color: #f8a442;">i</span><span style="color: #faa61f;">t</span><span style="color: #fdcc1d;">r</span><span style="color: #fed132;">o</span><span style="color: #fecd23;">n</span>Works</h1>
              <!-- <img src="/images/about-banner.jpg" class="w-100"> -->

              <h3 class="wow fadeInUp" data-wow-delay="0.1s">CitronWorks is a new global remote work and freelance marketplace.</h3>

              <h3 class="wow fadeInUp" data-wow-delay="0.2s">We have combined our 20+ years’ experience in remote work and remote team building with our expertise in matching people to form CitronWorks.</h3>

              <h3 class="wow fadeInUp" data-wow-delay="0.3s">One of our main goals is to do away with the industry standard commission-based model. Instead, we charge a small, flat monthly fee for employers and freelancing agencies. 
              </h3>

              <h3 class="wow fadeInUp" data-wow-delay="0.4s">We can also do the hiring for you with our <a href="/concierge" class="orange-text">Concierge Service</a>. Just tell us what you’re looking for, and we’ll do all the work of finding top candidates for you to choose from.
              </h3>
          </div>
      </div>  
    </div>
  </div>
 <!-- <div class="privacy-card mb-5">
    <div class="container about-us citronworkers">
      <div class="row align-items-center">
        <div class="col-md-6">
          <img src="/images/concierge-girl.png" class="w-100 px-5 mb-3 wow fadeInUp" data-wow-delay="0.4s">
        </div>
        <div class="col-md-6 wow fadeInUp px-5 text">
          <h2>Citronworks Team Management</h2>
          <h3 class="mt-0">We find and manage a remote team for you and bring on skilled, qualified staff who will help you grow your business. </h3>
          <h3>Our proven approach is a smart way for firms looking for that extra edge to access professional workforce support.</h3>
          <router-link to="/citronworkers" tag="button" class="el-button mt-4 pointer success read-more">Read more<i class="el-icon-d-arrow-right"></i></router-link>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="privacy-card">
    <div class="container about-us">
      <div class="row align-items-center">
        <div class="col-md-6 visible-xs">
          <img src="/images/concierge-2.png" class="w-100 px-5 mb-3 wow fadeInUp" data-wow-delay="0.4s">
        </div>
        <div class="col-md-6 wow fadeInUp px-5 text">
          <h2>Employers Concierge </h2>
          <h3 class="mt-0">With CitronWorks Concierge, you can get the top freelancers and remote workers delivered right to your inbox. </h3>
          <h3>Just tell us what you’re looking for, and we’ll do all the work of finding the top candidates for you to choose from.</h3>
          <router-link to="/concierge" tag="button" class="el-button mt-4 ml-0 pointer read-more">Read more <i class="el-icon-d-arrow-right"></i></router-link>
        </div>
        <div class="col-md-6 hidden-xs">
          <img src="/images/concierge-2.png" class="w-100 more-padding px-3 wow fadeInUp" data-wow-delay="0.4s">
        </div>
      </div>
    </div>
  </div> -->
  <div class="privacy privacy-card">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 wow fadeInUp">
          <img src="/images/about-us.png" class="w-100 most-padding wow fadeInUp" data-wow-delay="0.4s">
        </div>
        <div class="col-md-6 px-4 text wow fadeInUp">
          <h3 class="mt-0">The use of a global workforce is normally reserved for large companies and tech-savvy entrepreneurs, who typically save up to 50% on the payroll by hiring remotely. 
          </h3>
          <h3>It’s our ambition to create a place where anyone running a small to medium-size business can achieve similar saving, hiring remote workers or freelancers with confidence, as the world moves towards this new way of getting things done. </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="main privacy about-us" id="main"><!-- Main Section-->
    
    <div class="container">
      <div class="row justify-content-center">
          <div class="col-md-8 text-center wow fadeInUp">
              <h3 class="m-0">We are growing fast at the moment and we have many exciting features on the roadmap, such as a mutual trust score based on all aspects of both employers and freelancers’ interactions and connections.</h3>
          </div>
      </div>  
    </div>
  </div>
    

              
  
</div>
</template>

<script>
  import { WOW } from 'wowjs'
  export default {
    mounted () {
      new WOW().init()
    },
  }
</script>

<style scoped>
  .about-us{
    background: #f9f9f7;
    /*border-bottom: 1px solid lightgray;*/
    padding: 60px 50px;
    border-radius: 5px;
  }
  .about-us.citronworkers{
    padding: 40px 50px;    
  }
  .privacy h1 {
    font-size: 2.3rem;
    margin-bottom: 40px;
    color: #454545;
    text-align: center;
  }
  .privacy h3 {
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: .4px;
    color: #576874;
    margin: 20px 0 0 0;
  }
  .privacy-card h2 {
    font-size: 1.5rem;
    line-height: 1.8;
    letter-spacing: .4px;
    font-weight: 600;
    color: #E87722;
  }
  .privacy-card h3 {
    font-size: 1.25rem;
    line-height: 1.8;
    letter-spacing: .4px;
    color: #576874;
    margin: 20px 0 0 0;
  }
  .privacy ul {
    list-style: circle;
    padding-left: 30px;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  .privacy li {
    margin-bottom: 5px;
    font-size: .9rem;
  }
  .privacy p {
    line-height: 1.6;
    margin-bottom: 20px;
  }
  .privacy {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .privacy-card img {
    padding: 0 50px 0 110px;
  }
  button.success {
    background-color: #28a745;
    border-color: #28a745;
  }
  button {
    text-align: left;
    /*width: 100%;*/
    font-size: 1.1rem;
    color: #ffffff !important;
    background-color: #E87722;
    border: 1px solid #E87722;
    border-radius: 4px;
    padding: 8px 13px;
    font-weight: 400;
    line-height: 1.4;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    text-decoration: none;
  }
  button:hover {
    background-color: #e46709;
    border-color: #e46709;
  }

  @media (min-width: 992px) {
    .more-padding.px-5 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .most-padding.px-5 {
      padding-right: 6.5rem !important;
      padding-left: 6.5rem !important;
    }
    button i {
      float: right;
      line-height: 25px;
    }
  }
  @media (max-width: 767px) {
    .privacy, .about-us {
      padding: 50px 30px;
      text-align: center;
    }
    .privacy h1 {
      font-size: 1.75rem;
      margin-bottom: 30px;
    }
    .privacy h3 {
      font-size: 1.1rem;
      line-height: 1.8;
    }
    .privacy-card .px-5.text {
      padding: 0 15px !important;
    }
    .privacy-card img {
      padding: 0 80px;
      margin-bottom: 30px;
    }
  }
  @media (max-width: 500px) {
    .privacy-card img {
      padding: 0 30px;
     
    }
  }
</style>